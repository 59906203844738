// Generated by Framer (c60b0a0)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Typewriter from "https://framerusercontent.com/modules/vgSbxmWWvbgW6ShllXld/9oZlwlOxsp6zJVFpVkIp/Typewriter.js";
import * as localizedValues from "./t3afoshMj-0.js";
const TypewriterFonts = getFonts(Typewriter);

const cycleOrder = ["oDjKlynGr", "EHzim2SRm", "h7v4KjzyB"];

const serializationHash = "framer-RvClj"

const variantClassNames = {EHzim2SRm: "framer-v-e0jgjk", h7v4KjzyB: "framer-v-1i6m6k8", oDjKlynGr: "framer-v-166b6q3"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {A55YVbfVC: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "oDjKlynGr", "Variant 2": "EHzim2SRm", "Variant 3": "h7v4KjzyB"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "oDjKlynGr"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "oDjKlynGr", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppearure4be = activeVariantCallback(async (...args) => {
await delay(() => setVariant(CycleVariantState), 8000)
})

useOnVariantChange(baseVariant, {default: onAppearure4be})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-166b6q3", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"oDjKlynGr"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({EHzim2SRm: {"data-framer-name": "Variant 2"}, h7v4KjzyB: {"data-framer-name": "Variant 3"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-ynbtow-container"} layoutDependency={layoutDependency} layoutId={"Hp0aYue2f-container"}><Typewriter autoStart caretVisibility color={"var(--token-31258a9d-1bcb-4652-b98f-3c6e8e846a1d, rgb(17, 17, 17))"} cursor={"|"} cursorColor={"var(--token-74ce014a-bc9e-4b55-9e1b-25eb2dcdc168, rgb(147, 147, 147))"} delayNumber={0.15} delayType={false} font={{fontFamily: "Inter", fontSize: 16, fontWeight: 400, letterSpacing: 0, lineHeight: 1.2, lineHeightPixels: 24, lineHeightType: false, offset: 0, textAlign: "left", whiteSpace: "normal"}} height={"100%"} id={"Hp0aYue2f"} layoutId={"Hp0aYue2f"} loop pauseFor={2} split={false} style={{width: "100%"}} tag={"paragraph"} text={"Stylish Belgian Apartments for Rent"} width={"100%"} {...addPropertyOverrides({EHzim2SRm: {text: "Modern Rentals Available in Belgium"}, h7v4KjzyB: {text: "Belgium: Your Next Rental Awaits"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-RvClj.framer-1kodfcf, .framer-RvClj .framer-1kodfcf { display: block; }", ".framer-RvClj.framer-166b6q3 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: wrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: hidden; padding: 0px; position: relative; width: 300px; }", ".framer-RvClj .framer-ynbtow-container { flex: 1 0 0px; height: auto; position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-RvClj.framer-166b6q3 { gap: 0px; } .framer-RvClj.framer-166b6q3 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-RvClj.framer-166b6q3 > :first-child { margin-left: 0px; } .framer-RvClj.framer-166b6q3 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 300
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"EHzim2SRm":{"layout":["fixed","auto"]},"h7v4KjzyB":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framert3afoshMj: React.ComponentType<Props> = withCSS(Component, css, "framer-RvClj") as typeof Component;
export default Framert3afoshMj;

Framert3afoshMj.displayName = "Variant 1";

Framert3afoshMj.defaultProps = {height: 24, width: 300};

addPropertyControls(Framert3afoshMj, {variant: {options: ["oDjKlynGr", "EHzim2SRm", "h7v4KjzyB"], optionTitles: ["Variant 1", "Variant 2", "Variant 3"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framert3afoshMj, [{explicitInter: true, fonts: []}, ...TypewriterFonts], {supportsExplicitInterCodegen: true})